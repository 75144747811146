

body,
p {
  font: normal 20px/1.25rem;
}

h1 {
  font: normal 2.75rem/1.05em;
}

h2 {
  font: normal 2.25rem/1.25em;
}

h3 {
  font: lighter 1.5rem/1.25em;
}

h4 {
  font: lighter 1.125rem/1.2222222em;
}

/* .container {
  width: 90%;
  margin: auto;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  text-align: center;
} */

.bsod {
  padding-top: 10%;
  font-weight: bold;
  text-align: center;
  max-width: 800px;
}
.bsod .neg {
  text-align: center;
  color: #7fad40;
  margin-top: 15px;
}

.bsod .neg .bg {
 /* background: #7fad40;*/
  padding: 0 15px 2px 13px;
  border-radius: 6px;
}
.bsod .title {
  margin-top: 30px;
  margin-bottom: 30px;
}
.bsod .nav {
  margin-top: 35px;
  text-align: center;
}
.bsod .nav .link {
  text-decoration: none;
  padding: 0 9px 2px 8px;
}
.bsod .nav .link:hover, .bsod .nav .link:focus {
  color: #7fad40;
}

.browser-ico {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
